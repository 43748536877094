import React from 'react';
//, { Fragment, useState, useEffect }
//import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
//import { Icon } from 'react-icons-kit';
//import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
//import Heading from 'common/components/Heading';
//import Text from 'common/components/Text';
//import Button from 'common/components/Button';
//import Input from 'common/components/Input';
//import GlideCarousel from 'common/components/GlideCarousel';
//import GatsbyImage from 'common/components/GatsbyImage';
//import GlideSlide from 'common/components/GlideCarousel/glideSlide';
//import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  ContentArea,
  //HighlightedText,
  //FormWrapper,
  //ButtonGroup,
  //CarouselArea,
} from './banner.style';

//import IBEXLogo from '../../../../common/assets/image/interior/IbexImages/IbexBionomicsLogo.png'
import SubTiSoil from '../../../../common/assets/image/interior/IbexImages/S19/S19SubtisoilText.png'
import Novater from '../../../../common/assets/image/interior/IbexImages/S19/S19NovaterText.png'

const S20SubtisoilAndNovaterP2 = () => {
  
  {/**
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        S5RhyzomacData {
          title
        }
      }
    }
  `);

  const { title } =
  data.interiorJson.S5RhyzomacData;
 */}
 
  return(
    <div> 
      <BannerWrapper>
        <Container>
          <ContentArea minWidth="100vw" minHeight="100vh" >
            <div >
                <Fade bottom >
                <div>
                  <p className="shadow">
                    <p className="shadowDark">
                      <b>
                      Subtisoil and Novater are composed of facultative microorganisms possessing excellent action capacity for the generation of suppressive soils:
                      </b>
                    </p>
                  </p>
                  <div className="flex-row">
                    <div className="shadow">
                      <ol className="shadowDark">
                        <li>Support wide ranges of pH levels. (3-12), but their higher expression level is achieved between pHs of 4.5 to 10.5.</li>
                        <li>Withstand wide temperature ranges (1 - 99°C) but their highest expression level is achieved between 5 to 50ºC.</li>
                        <li>Hold up to high concentrations of salts and heavy metals.</li>
                        <li>Withstand high exposure to ultraviolet light.</li>
                        <li>Mitigate the environmental impacts generated by vectors and odors as a result of decomposition of organic materials.</li>
                        <li>Contribute to humification of organic matter.</li>
                      </ol>
                    </div>
                    <div className="shadow">
                      <ol className="shadowDark">
                        <li>Contribute to fixing organic matter in soils .</li>
                        <li>Degrade fats and oils, both organic and inorganic, that cause nutrient immobilization in soils.</li>
                        <li>Activate the biokinetics of soils and bodies of water.</li>
                        <li>Contribute to detoxification of soils resulting from contaminating compounds (organochlorines, organophosphates etc.) from prior chemical applications.</li>
                        <li>Prevent the generation of gases, such as methane, hydrogen sulfide, and ammonia compounds, in the decomposition of organic matter.</li>
                      </ol>
                    </div>
                  </div>
                </div>
                </Fade>
              </div>
            </ContentArea>
        </Container>
      </BannerWrapper>
    </div>
  )
};
    


export default S20SubtisoilAndNovaterP2;
