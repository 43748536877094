import React from 'react';
//, { Fragment, useState, useEffect }
//import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
//import { Icon } from 'react-icons-kit';
//import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
//import Heading from 'common/components/Heading';
//import Text from 'common/components/Text';
//import Button from 'common/components/Button';
//import Input from 'common/components/Input';
//import GlideCarousel from 'common/components/GlideCarousel';
//import GatsbyImage from 'common/components/GatsbyImage';
//import GlideSlide from 'common/components/GlideCarousel/glideSlide';
//import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  ContentArea,
  //HighlightedText,
  //FormWrapper,
  //ButtonGroup,
  //CarouselArea,
} from './banner.style';

//import IBEXLogo from '../../../../common/assets/image/interior/IbexImages/IbexBionomicsLogo.png'

const S18PlantGrowthP2 = () => {
  
  {/**
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        S5RhyzomacData {
          title
        }
      }
    }
  `);

  const { title } =
  data.interiorJson.S5RhyzomacData;
 */}
 
  return(
    <div> 
      <BannerWrapper>
        <Container>
          <div className="flex">
            <ContentArea minWidth="50vw" minHeight="50vh" className="alignTop">
              <Fade bottom>
                <div className="shadow">
                  <p className="shadowDark">
                    <b>PGPR is the treatment and protection of soils by the biopotentiation of the rhizosphere, be it for protecting autochthonous organisms or through the inoculation of living microorganisms.</b>
                    <br/><br/>The microorganisms colonize the root surface and adhere to the soil interface.
                    <br/><br/>Free rhizobacteria and endophytes use common mechanisms to promote the growth of the plants and to control phytopathogens.
                    <br/><br/>Rhizobacteria contribute to fix the nitrogen in the soil, to solubilize phosphorus, and to increase the bioavailability of potassium and mineral elements for plants. Their principal contribution is directed to the stabilization of organic materials, providing the soil with exceptional physico-chemical characteristics.
                    <br/><br/>Rhizobacteria are antagonistic to the presence of pathogenic organisms.
                  </p>
                </div>
              </Fade>
              <Fade bottom>
                <div className="shadow">
                  <p className="shadowDark">
                    <b>USES IN SOIL TREATMENT</b>
                    <br/><br/>
                    <p className="textAlignLeft">
                    Contributes to the mineralization process of toxic and dangerous compounds from previous agrochemical applications, rendering them inert.<br/><br/>
                    Accelerates the stabilization process of organic mater in soils so as to greatly reduce its ability to become host to pathogenic microorganisms, while mitigating environmental impacts generated by contaminating odors and vectors.
                    </p>
                  </p>
                </div>
              </Fade>
            </ContentArea>
            <ContentArea minWidth="50vw" minHeight="100vh" >
              <div >
                <Fade bottom >
                <div className="shadow">
                  <p className="shadowDark">
                    <p><b>Control Mechanisms</b></p>
                    <br/>
                      <p className="textAlignLeft">
                        Our suppressive soil products use the following mechanisms for pathogen control:
                      
                    <ol>
                      <li className="smPadding">Direct parasitism leading to the death of the pathogen.</li>
                      <li className="smPadding">Rhyzospheric competition with pathogens for space and food source.</li>
                      <li className="smPadding">Direct toxic effects on the pathogen through the release of antibiotic or alelochemical substances (organic acids, aminoacids and specific sugars, siderophores, volatile biocidal antibiotics, litic enzymes, detoxifying enzymes).</li>
                      <li className="smPadding">Indirect toxic effects through the release of volatile substances through antagonistic activity, such as ethylene.</li>
                      <li className="smPadding">Induction of Systemic Resistance (ISR) of plants.</li>
                    </ol>
                    The lictic enzymes produced by the PGPR are hydrolases (degrading the celular walls of pathogenic fungi), chitinases (degrading the chitin of pathogenic fungi and insects), proteases (bacteriocins), and glucanases (viricidal, micocidal, insecticidal) .<br/><br/>
                    The	detoxifying enzimes degrade enzymes that are considered pathogenic factors (cutinase, endo and exo-polygalacturonases, esterases, lyases, proteases, etc). With these they control the first stages of the attack by fungi to the plants tissue.<br/><br/>
                    One of the mayor positive effects of the production of alelochemical substances from the aforementioned antagonistic rhizobacterias is the degradation of the auto-inducing signals of pathogens (Quorum Sensing), thus blocking the expression of numerous pathogenic genes produced by various species of fungi and bacteria.<br/><br/>
                    The induction of systemic resistance of the plants (ISR) is narrowly linked to bacterias of the bacillus kind, especially subtillis, agglomerans, megaterium and pseudomonas. This mechanism allows plants to reduce the diseases produced by fungi, bacteria or viruses.
                    </p>
                  </p>
                </div>
                </Fade>
              </div>
            </ContentArea>
          </div>
        </Container>
      </BannerWrapper>
    </div>
  )
};
    


export default S18PlantGrowthP2;
